.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.news-card {
  cursor: pointer;
  padding: 5px;
  display: flex;
  /* border: 1px solid #ccc; */
  background: white;
  border-radius: 8px;
  margin-bottom: 20px;
  /* height: 60px; */
  min-height: 60px;
}
.card-left-side {
  width: 70%;
  padding: 0;
  text-align: center;
}

.card-left-side p {
  font-weight: 500;
  font-size: 15px;
  text-align: start;
}
.card-right-side {
  width: 30%;
  border-radius: 5px;
  overflow: hidden;
}
.card-right-side img {
  width: 100%;
  height: 100%;
}
.news-container {
  padding: 0 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ccc;
  background: #f1f1f1;
  border-radius: 8px;
}
.news-card-container {
  width: 50%;
}

@media screen and (max-width: 768px) {
  .news-card {
    height: auto;
  }

  .card-left-side {
    width: 60%;
    padding: 0;
    text-align: start;
  }

  .card-right-side {
    display: flex;
    width: 40%;
    align-items: center;
  }
  .card-left-side p {
    /* font-weight: 400; */
    font-weight: bold;
    font-size: 10px;
  }
  .news-card-container {
    width: 100%;
  }
}

.ad-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
